<template>
  <!-- {$rotate=""}
  {if $row["signRotate"] == true}:rotate="'fa-rotate-180'"{/if}-->
  <div>
    <remoteTooltip
      v-if="
        !empty(row['packetDirection']) ||
        (typeof row['c_messages']['icon'] != 'undefined' &&
          row['c_messages']['icon'] == 'info')
      "
      path="serve.php?f=testing&f2=testInfo&tooltip=true"
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="dialogAttributes"
    >
      <addField
        :path="'serve.php?f=testing&f2=testInfo'"
        :additionalAttributes="additionalAttributes"
        :dialogAttributes="dialogAttributes"
        v-on="$listeners"
        :showDialog="showDialog"
        @dialog-closed="closeDialog"
        @dialog-loaded="setDialogOpen"
      >
        <v-icon
          v-if="
            !empty(row['packetDirection']) &&
            row['packetDirection'] == 'packetOut'
          "
          >mdi-logout</v-icon
        >
        <v-icon
          v-else-if="
            !empty(row['packetDirection']) &&
            row['packetDirection'] == 'packetIn'
          "
          >mdi-login</v-icon
        >
        <v-icon v-else-if="row['c_messages']['icon'] == 'info'"
          >mdi-information</v-icon
        >
      </addField>
    </remoteTooltip>
    <!--  -->
    <!-- <span
      v-if="!empty(row['packetDirection'])"
      @click="testInfoDialog();"
      onmouseover="testInfoTooltip();"
    >
      <a>
        p
    <i class="fas fa-sign-{{row['signDirection']}}-alt fs16"></i>-->
    <!-- </a>
    </span>

    <span
      v-if="isset(row['c_messages']['icon'])"
      @click="testInfoDialog();"
      onmouseover="testInfoTooltip();"
    ></span>-->
  </div>
</template>
    <script>
import remoteTooltip from "@/commonComponents/remoteTooltip.vue";
import addField from "@/commonComponents/addField.vue";
export default {
  components: { addField, remoteTooltip },
  props: ["row", "result", "rowindex"],
  data() {
    return {
      showDialog: false,
      dialogCall: ''
    };
  },
  created: function () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      this.dialogCall = ''; // to avoid two times calling, once user click test info icon, other from direction click in dialog
      if (mutation.type === "openInfoDialog") {
        if (state.openedDialogIndex == this.row.order) {
          this.showDialog = true;
        } else {
          this.showDialog = false;
        }
        this.dialogCall = 'dialogDirectionButton';
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },

  computed: {
    dialogAttributes() {
      return {
        data: this.result.table.data,
        currentID: this.row["n_id"],
        row: this.row,
      };
    },
    additionalAttributes() {
      let indexVal = this.row["packetDirection"];
      if (
        typeof this.row["rawType"] != "undefined" &&
        this.row["rawType"] == "INFO"
      ) {
        indexVal = "packetOut";
      }
      return {
        function: "getRemotePopupInfo",
        index: indexVal,
        dialogCall: this.dialogCall,
        id: this.row["n_id"],
        requestType: "ajax",
      };
    },
    //       <!-- {assign var="all" value="{{row['n_id']}}_{{row['packetDirection']}}"}
    // {$container=array_push(result.allCluster,$all)} -->
  },
  methods: {
    setDialogOpen() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
// function testInfoDialog(caller, index, id) {
//   // <!-- this,'{{row['packetDirection']}}','{{row['n_id']}}' -->
//   var $caller = $(caller);
//   var $reportTable = $caller.closest("table");
//   var $callerRow = $caller.closest("tr");
//   var order = $callerRow.find(".order").text();
//   var startTime = $callerRow.find(".stepStartTime").text();
//   var stepType = $callerRow.find(".stepType").text();
//   options = {
//     function: "getRemotePopupInfo",
//     index: index,
//     id: id,
//     requestType: "ajax",
//   };
//   sentURI = "serve.php?f=testing&f2=testInfo";
//   dialogOptions = {
//     width: 700,
//     minHeight: 50,
//     maxHeight: 550,
//     close: function (event, ui) {
//       var $caller = $(event.target).data("callerButton");
//       $caller.closest("tr").removeClass("hoveredLine");
//     },
//   };
//   var title = order + ": " + startTime + ": " + stepType;
//   var callBackFunction = function ($dialog, data, caller) {
//     var $treeContainer = $dialog.find(".tree");
//     //sortpacketTree($treeContainer);
//     var $xmlContent = $dialog.find(".xmlContent");
//     $xmlContent.each(function () {
//       let count = getTextAreaLineCount(this);
//       this.style.height = count * 25 + "px";
//     });
//     var $originalXmlContent = $dialog.find(".originalXmlContent");
//     $originalXmlContent.each(function () {
//       let count = getTextAreaLineCount(this);
//       this.style.height = count * 25 + "px";
//     });

//     var $treeContent = $dialog.find(".treeContent");
//     $treeContent.each(function () {
//       let count = getTextAreaLineCount(this);
//       if (count > 600) {
//         count = 600;
//       } else {
//         count = 300;
//       }
//       this.style.height = count + "px";
//     });

//     $dialog.find(".searcharea").show();
//     var $caller = $(caller);
//     $dialog.data("callerButton", $caller);
//     let $packetPaging = $(
//       '<div class="packetPaging" style="float: right"></div>'
//     ).prependTo($dialog);
//     $packetPaging.append(
//       ' <span onclick="getPacketDetailsPrev(this);return false;"><a title="Previous" style="cursor: pointer;font-size:2em;vertical-align: middle;color: #4297d7" class="fa fa-chevron-circle-left "> </a></span>'
//     );
//     $packetPaging.append(
//       ' <span onclick="getPacketDetailsNext(this);return false;"><a title="Next" style="cursor: pointer;font-size:2em;vertical-align: middle;color: #4297d7" class="fa fa-chevron-circle-right "> </a></span>'
//     );
//     checkTranslationExist();
//     var treeView = $(".treeViewList").val();
//     if (typeof treeView != "undefined") {
//       $(".original").show();
//       $(".showTranslated").hide();
//     }

//     $(".autoGrow").resizeTextArea(); //resize the text area Automatically!
//   };
//   $reportTable.find("tr").removeClass("hoveredLine");
//   var $returnedDialog = getDialog(
//     sentURI,
//     options,
//     dialogOptions,
//     title,
//     true,
//     callBackFunction,
//     index + id,
//     caller
//   );
//   $returnedDialog.closest(".ui-dialog").hover(
//     function () {
//       $callerRow.addClass("hoveredLine");
//     },
//     function () {
//       $callerRow.removeClass("hoveredLine");
//     }
//   );
// }

// function testInfoTooltip(caller, index, id) {
//   // <!-- this,'{{row['packetDirection']}}','{{row['n_id']}}' -->
//   options = {
//     function: "getRemotePopupInfo",
//     index: index,
//     id: id,
//     requestType: "ajax",
//   };
//   sentURI = "serve.php?f=testing&f2=testInfo";
//   dialogOptions = { width: 230, minHeight: 50 };
//   title = "Switch Project";
//   var additionalTooltipOptions = {
//     // better keep the default postion as it shows better rendering
//     /* position: {
//       my: "left top",
//       at: "right top",
//       within :"#pageWarper"
//     },*/
//   };

//   var callBackFunction = function (returnedObject, data) {
//     //console.log(returnedObject);
//     let $returnedObject = $(returnedObject);
//     if ($returnedObject.data("ui-tooltip")) {
//       if (/<[a-z][\s\S]*>/i.test(data)) {
//         var $data = $($.parseHTML(data));
//         var $content = $("<div class='tooltipcontainer'><div>");
//         $data.find(".xmlContent").each(function () {
//           //console.log("here");
//           this.style.height = "200px";
//           //this.style.height = (this.scrollHeight)+"px";
//         });
//         //var $treeContainer=$data.find(".tree");
//         //sortpacketTree($treeContainer);
//         $content.append($data);
//         $returnedObject.tooltip("option", "content", $content);
//       }
//       checkTranslationExist();
//       var treeView = $(".treeViewList").val();
//       if (typeof treeView != "undefined") {
//         $(".original").show();
//         $(".showTranslated").hide();
//       }
//     }
//   };
//   var $returnedDialog = getRemoteTooltip(
//     caller,
//     "",
//     options,
//     additionalTooltipOptions,
//     callBackFunction,
//     index + id
//   );
// }
</script>

      
